import {Injectable} from '@angular/core';
import {
  Action,
  State,
  Selector,
  StateContext, createSelector,
} from '@ngxs/store';
import {patch} from '@ngxs/store/operators';
import {UserStateModel} from './user-state.models';
import {UserStateActions} from "./user-state.actions";

const STATE_NAME = 'UserState';

@State<UserStateModel>({
  name: STATE_NAME,
  defaults: {
    email: '',
    isLoggedIn: false,
    profile: null
  },
})
@Injectable()
export class UserState {
  @Selector()
  static user(state: UserStateModel): UserStateModel | null {
    return state;
  }

  @Selector()
  static isLoggedIn(state: UserStateModel): boolean {
    return state.isLoggedIn;
  }

  @Action(UserStateActions.SetEmail)
  setEmail(ctx: StateContext<UserStateModel>, action: UserStateActions.SetEmail) {
    ctx.patchState(<UserStateModel>({
      email: action.email,
    }));
  }

  @Action(UserStateActions.SetIsLoggedIn)
  setIsLoggedIn(ctx: StateContext<UserStateModel>, action: UserStateActions.SetIsLoggedIn) {
    ctx.patchState(<UserStateModel>({
      isLoggedIn: action.isLoggedIn
    }));
  }

  @Action(UserStateActions.SetProfile)
  setProfile(ctx: StateContext<UserStateModel>, action: UserStateActions.SetProfile) {
    ctx.patchState(<UserStateModel>({
      profile: action.profile
    }));
  }

  @Action(UserStateActions.DeleteUser)
  deleteUser(ctx: StateContext<UserStateModel>, action: UserStateActions.DeleteUser) {
    ctx.patchState(<UserStateModel>({
      profile: null,
      email: '',
      isLoggedIn: false
    }));
  }


}

